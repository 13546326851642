<template src="./index.html" />

<script>
import {
  BBreadcrumb, BButton, BCard, BCol, BContainer, BFormSelect, BOverlay, BPagination, BRow, BLink, BTooltip, VBTooltip, BForm,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapActions, mapGetters } from 'vuex'
import '@core/scss/vue/libs/vue-good-table.scss'
import Form from '@/views/research/fact_research_roles/Form.vue'
import vSelect from 'vue-select'
import { hasPermissionForResource, isSystemAdmin } from '@/utils'
import { PermissionCode, ResourceCode } from '@/const/code'
import { STATUSES } from '@/const/status'
import { Flag } from '@/const/flag'

export default {
  name: 'Index',
  components: {
    VueGoodTable,
    vSelect,
    BBreadcrumb,
    BButton,
    BCard,
    BCol,
    BContainer,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    BLink,
    BTooltip,
    BForm,
    Form,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      isLoading: false,
      table: {
        fields: [
          {
            label: 'Tên',
            field: 'name',
            thClass: 'text-center',
            filterOptions: {
              enabled: true,
              trigger: 'enter',
              placeholder: 'Nhập tên',
            },
          },
          {
            label: 'Trạng thái',
            field: 'status',
            sortable: false,
            thClass: 'text-center',
            filterOptions: {
              enabled: true,
              trigger: 'enter',
              filterDropdownItems: STATUSES.map(status => ({ value: status.value, text: status.label })),
              placeholder: 'Chọn trạng thái',
            },
          },
          {
            label: 'Thứ tự',
            field: 'orderNo',
            sortable: true,
            thClass: 'text-center',
            tdClass: 'text-right',
          },
          {
            label: 'Thao tác',
            field: 'action',
            thClass: 'text-center',
            tdClass: 'text-center',
            sortable: false,
          },
        ],
      },
      filter: {
        currentPage: 1,
        itemsPerPage: 10,
        name: null,
        status: null,
        sort: null,
      },
      dataForm: {},
    }
  },
  computed: {
    // calculate data from init data
    ...mapGetters({
      dataList: 'factResearchRoles/dataArr',
      total: 'factResearchRoles/total',
    }),
    creatable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.CREATE, ResourceCode.FACT_RESEARCH_ROLE)
    },
    updatable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.UPDATE, ResourceCode.FACT_RESEARCH_ROLE)
    },
    deletable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.DELETE, ResourceCode.FACT_RESEARCH_ROLE)
    },
  },
  async created() {
    // init data FROM API
    await this.getInitData()
  },
  methods: {
    // handle even
    ...mapActions({
      getData: 'factResearchRoles/getData',
      deleteData: 'factResearchRoles/deleteData',
    }),

    async updateParams(newProps) {
      this.filter = { ...this.filter, ...newProps }
    },
    async onPageChange(params) {
      this.updateParams({ page: params.currentPage })
      await this.getInitData()
    },
    async onPerPageChange(params) {
      this.updateParams({ currentPage: 1, itemsPerPage: params.currentPerPage })
      await this.getInitData()
    },
    async onSortChange(param) {
      if (param.length > 0) {
        const { field, type } = param[0]
        if (field === 'name') this.updateParams({ sort: `1_${type}` })
        else this.updateParams({ sort: `2_${type}` })
        await this.getInitData()
      }
    },
    async onColumnFilter(param) {
      const { columnFilters } = param
      if (Object.hasOwn(columnFilters, 'name')) this.updateParams({ name: columnFilters.name })
      if (Object.hasOwn(columnFilters, 'status')) this.updateParams({ status: columnFilters.status })
      await this.getInitData()
    },

    async getInitData() {
      this.isLoading = true
      try {
        await this.getData(this.filter)
      } catch (e) {
        this.showToast(`[${e.code}] ${e.message}`, 'XCircleIcon', 'danger')
      } finally {
        this.isLoading = false
      }
    },

    editItem(data) {
      const {
        rowNum,
        originalIndex,
        vgt_id,
        ...rest
      } = data
      this.dataForm = rest
      this.$bvModal.show('form')
    },

    deleteItem(data) {
      this.$swal
        .fire({
          title: 'Xóa bản ghi này?',
          text: 'Bạn sẽ không thể khôi phục dữ liệu này sau khi xóa !',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: '<i class="fa-solid fa-check"></i> Đồng ý',
          cancelButtonText: '<i class="fa-solid fa-times"></i> Hủy',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        })
        .then(async result => {
          if (result.isConfirmed) {
            this.isLoading = true
            try {
              const response = await this.deleteData(data.id)
              if (response) {
                const { isSuccessful, message } = response
                if (isSuccessful) {
                  this.showToast('Đã xóa thành công!', 'CheckIcon', 'success')
                  await this.getInitData()
                } else {
                  this.showToast(`Có lỗi xảy ra ${message}`, 'XCircleIcon', 'warning')
                }
              }
            } catch (e) {
              this.showToast(`[${e.code}] ${e.message}`, 'XCircleIcon', 'danger')
            } finally {
              this.isLoading = false
            }
          }
        })
    },

    showModal() {
      this.$bvModal.show('form')
      this.dataForm = {
        rowNum: null,
        name: '',
        status: Flag.ACTIVE,
      }
    },

    async saveDataSuccess() {
      this.$bvModal.hide('form')
      await this.getInitData()
    },

    getStatusName(id) {
      return STATUSES.find(status => status.value === id).label
    },

    showToast(title, icon, variant) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon,
          variant,
        },
      })
    },

  },
}
</script>
